
var textWidthCanvas = null;

function drawGraph(svg, data, canvas) {

    if (typeof(data) !== 'object' || typeof(data.options) !== 'object' || !data.options.stationNumberTrimmed) {
        console.error('Cannot render water level graph: invalid data', data);
        return;
    }
    
    if (!textWidthCanvas) {
        if (canvas) {
            // provided by node script
            textWidthCanvas = canvas;
        } else {
            // in browser
            textWidthCanvas  = document.createElement('canvas');
        }
    }
    
    var stationNumber = data.options.stationNumberTrimmed;

    var options = Object.assign({
        width: 500,
        height: 300,
        displayDays: 5,
        showLegend: false,
        graphMin: null,
        graphMax: null,
        title: null,
        yTicks: null,
        estimationThreshold: 0,
        waterLevelUnit: null,
        interactive: true,
        css: null,
        // Correctly positioning some elements requires calculating of text widths,
        // which requires knowing font family and size. Because CSS aren't applied
        // before rendering, font family and size cannot be determined beforehand.
        fontFamily: 'Open Sans',
        fontSize: 14 // px
    }, data.options);
    
    if (!options.waterLevelUnit) {
        console.error('Not rendering water level graph: water level unit is missing');
        return;
    }
    
    // Split data into actual measurements and simulated values
    var measuredLevels = [];
    var simulatedLevels = [];
    var estimatedLevels = [];
    
    if (typeof(data.levels) !== 'object') {
        data.levels = [];
    }
    
    data.levels.sort(function(a, b) {
        return (a.time - b.time);
    });

    var estimationThresholdDate = false;
    if (typeof(options.estimationThreshold) === 'number' && options.estimationThreshold > 0) {
        estimationThresholdDate = new Date();
        estimationThresholdDate.setSeconds(estimationThresholdDate.getSeconds() + options.estimationThreshold * 3600);
    }
        
    data.levels.forEach(function(dp) {
        dp.timeParsed = new Date(0);
        dp.timeParsed.setUTCMilliseconds(dp.time);
        dp.level = +dp.level;
        if (dp.simulated) {
            if (estimationThresholdDate && dp.time >= estimationThresholdDate.getTime()) {
                estimatedLevels.push(dp);
            } else {
                simulatedLevels.push(dp);
            }
        }
        else
            measuredLevels.push(dp);
    });
    
    var hasData = (measuredLevels.length !== 0);
    var showLegend = (hasData && options.showLegend);

    var shownLevels = [];
    var shownMeasuredLevels;
    
    if (hasData) {
        var mostRecentMeasuredLevel = measuredLevels[measuredLevels.length - 1];
        var lowerTimeLimitMs = mostRecentMeasuredLevel.time - options.displayDays * 24*60*60*1000;
        var shownMeasuredLevels = measuredLevels.filter(function(dp) {
            return (dp.time >= lowerTimeLimitMs);
        });
        shownLevels = shownMeasuredLevels.concat(simulatedLevels).concat(estimatedLevels);
    }
    
    // Precalc y domain extent
    var yRelativePadding = 0.25;
    var yMin = (shownLevels.length === 0 ? 0 : d3.min(shownLevels, function(dp) { return dp.level; }));
    var yMax = (shownLevels.length === 0 ? 0 : d3.max(shownLevels, function(dp) { return dp.level; }));
    var ySpan = yMax - yMin;
    var yDomainMin = Math.max(0, yMin - ySpan * yRelativePadding);
    var yDomainMax = yMax + ySpan * yRelativePadding;
    
    // Apply manual bounds where needed
    if (typeof(options.graphMin) == 'number' && yDomainMin > options.graphMin) {
        yDomainMin = options.graphMin;
    }
    if (typeof(options.graphMax) == 'number' && yDomainMax < options.graphMax) {
        yDomainMax = options.graphMax;
    }

    if (options.showLegend) {
        // Legend
        var legend = new GraphLegend(options.fontFamily, options.fontSize);
        if (hasData) {
            legend.entries.push(new GLEntry('area', 'levels area line measured', L('graph.legend.measured')));
        }
    }
    
    // Cotes
    var coteNames = ['vigilance', 'prealerte', 'alerte'];
    var visibleCotes = {};
    if (hasData) {
        for (var i = 0; i < coteNames.length; i++) {
            var cn = coteNames[i];
            var coteLevel = data.thresholds && data.thresholds['cote-' + cn];
            if (typeof(coteLevel) === 'undefined' || coteLevel === null)
                continue;
            if (coteLevel < yDomainMin || coteLevel > yDomainMax)
                continue;
            if (options.showLegend) {
                legend.entries.push(new GLEntry('area', 'levels area line ' + cn, L('graph.legend.' + cn)));
            }
            removeClass('[data-legend-cote-' + cn + '="' + stationNumber + '"]', 'hidden');
            visibleCotes[cn] = {
                name: cn,
                level: coteLevel
            };
        }
    }
    
    if (simulatedLevels.length !== 0) {
        if (options.showLegend) {
            legend.entries.push(new GLEntry('area', 'levels area line simulated', L('graph.legend.simulated')));
        }
        removeClass('[data-legend-simulated="' + stationNumber + '"]', 'hidden');
    }
    if (estimatedLevels.length !== 0) {
        if (options.showLegend) {
            legend.entries.push(new GLEntry('area', 'levels area line estimated', L('graph.legend.estimated')));
        }
        removeClass('[data-legend-estimated="' + stationNumber + '"]', 'hidden');
    }
    
    // CREATE GRAPH CONTAINER, TITLE AND LEGEND
    
    if (!svg) {
        svg = d3.create('svg');
    }
    svg.attr('class', 'water-level-graph')
            .attr('width', options.width)
            .attr('height', options.height);
    
    if (options.css) {
        svg.append('style').text(options.css);
    }
    
    var headerMargin = 10;
    
    if (options.title !== null) {
        var g = svg.append('g').attr('class', 'title');
        var titleElem = g.append('text').text(options.title);
        var textWidth = getTextWidth(titleElem.node().textContent, options.fontFamily, options.fontSize);
        var fontSize = window.getComputedStyle(titleElem.node()).getPropertyValue('font-size');
        fontSize = fontSize ? parseFloat(fontSize) : 0;
        titleElem.attr('x', (options.width - textWidth) / 2)
                 .attr('y', (options.height - fontSize) / 2);
        titleElem.attr('transform', 'translate(' + ((options.width - legend.getWidth()) / 2) + ', ' + headerMargin + ')');
        headerMargin += 20;
    }
    
    if (options.showLegend) {
        var legendElem = legend.create(svg, options.width);
        legendElem.attr('transform', 'translate(' + ((options.width - legend.getWidth()) / 2) + ', ' + headerMargin + ')');
        headerMargin += legend.getHeight();
    }

    // CALCULATE GRAPH PROPERTIES
    
    // Use yDomainMax as a sensible lower limit if no water levels are available.
    // (ceil() -> safeguard against precision oddities like 109.999999999999)
    var maxLevelLength = getLevelDisplayLength(Math.ceil(yDomainMax));
    for (var i = 0; i < shownLevels.length; i++) {
        var length = getLevelDisplayLength(shownLevels[i].level);
        if (length > maxLevelLength) {
            maxLevelLength = length;
        }
    }
    
    var margin = {
        top: 30 + headerMargin,
        right: 20,
        bottom: 60,
        left: 20 + (maxLevelLength + 1) * 12
    };

    var graphWidth = options.width - margin.left - margin.right;
    var graphHeight = options.height - margin.top - margin.bottom;

    // Define axes
    var x = d3.scaleTime([0, graphWidth]);
    var y = d3.scaleLinear([graphHeight, 0]);

    var xAxis = d3.axisBottom(x)
            .tickFormat(d3.timeFormat("%d.%m.%y"))
            .ticks(d3.timeDay, 1);
    var yAxis = d3.axisLeft(y);
    var yAxisTicks = d3.axisLeft(y)
    if (options.yTicks !== null) {
        yAxis = yAxis.ticks(options.yTicks);
        yAxisTicks = yAxisTicks.ticks(options.yTicks);
    }

    // Define generators for graph content
    // Area plot
    var area = d3.area()
            .x(function(dp) {
                return x(dp.time);
            })
            .y0(graphHeight)
            .y1(function (dp) {
                return y(dp.level);
            });

    // Line plot
    var line = d3.area()
            .x(function(dp) {
                return x(dp.time);
            })
            .y(function (dp) {
                return y(dp.level);
            });

    // Domains
    if (hasData) {
        x.domain(d3.extent(shownLevels, function(dp) {
            return dp.timeParsed;
        }));
    } else {
        // No data -> default to showing ticks for the last 'displayDays' days
        var xDomainMax = new Date();
        var xDomainMin = new Date(xDomainMax - 86400 * 1000 * options.displayDays);
        x.domain([xDomainMin, xDomainMax]);
    }

    y.domain([yDomainMin, yDomainMax]);
    
    // Partition measured levels according to cotes
    var mlPartitions = {
        'normal': new GraphPartition('normal', y(yDomainMin))
    };
    var pPrevious = mlPartitions['normal'];
    for (var i = 0; i < coteNames.length; i++) {
        var cn = coteNames[i];
        if (typeof(visibleCotes[cn]) === 'undefined')
            continue;
        var yLevel = y(visibleCotes[cn].level);
        mlPartitions[cn] = new GraphPartition(cn, yLevel);
        pPrevious.yMax = yLevel;
        pPrevious = mlPartitions[cn];
    }
    pPrevious.yMax = y(yDomainMax);
    
    // CREATE GRAPH CONTENT

    var graph = svg.append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    
    if (!hasData) {
        var gNoData = svg.append('g').attr('class', 'no-data');
        var noData = gNoData.append('text')
                .text(L('stationGraph.noRecentDataAvailable'))
                .attr('x', margin.left + graphWidth / 2)
                .attr('y', margin.top + graphHeight / 2)
                .style('text-anchor', 'middle');
    } else {
        // Estimated levels
        if (estimatedLevels.length !== 0) {
            var estimatedLevelsArea = graph.append('path')
                    .datum(estimatedLevels)
                    .attr('class', 'levels area estimated')
                    .attr('d', area);
            var estimatedLevelsLine = graph.append('path')
                    .datum(estimatedLevels)
                    .attr('class', 'levels line estimated')
                    .attr('d', line);
        }

        // Simulated levels
        if (simulatedLevels.length !== 0) {
            var simulatedLevelsArea = graph.append('path')
                    .datum(simulatedLevels)
                    .attr('class', 'levels area simulated')
                    .attr('d', area);
            var simulatedLevelsALine = graph.append('path')
                    .datum(simulatedLevels)
                    .attr('class', 'levels line simulated')
                    .attr('d', line);
        }

        var PARTITION_GAP_THRESHOLD = 3660 * 1000; // 1h 1min
        var partition = [];
        var lastLevel = null;
        var measuredLevelPartitions = [ partition ];
        for (var i = 0; i < shownMeasuredLevels.length; i++) {
            if (lastLevel !== null && shownMeasuredLevels[i].time > lastLevel.time + PARTITION_GAP_THRESHOLD) {
                partition = [];
                measuredLevelPartitions.push(partition);
                lastLevel = null;
            } else {
                lastLevel = shownMeasuredLevels[i];
            }
            partition.push(shownMeasuredLevels[i]);
        }

        for (var i = 0; i < measuredLevelPartitions.length; i++) {
            var measuredLevelsArea = graph.append('path')
                    .datum(measuredLevelPartitions[i])
                    .attr('class', 'levels area measured')
                    .attr('d', area);
            var measuredLevelsLine = graph.append('path')
                    .datum(measuredLevelPartitions[i])
                    .attr('class', 'levels line measured')
                    .attr('d', line);
        }
    }
    
    // Cote line of measured levels above cote level
    for (var i = 0; i < coteNames.length; i++) {
        var cn = coteNames[i];
        if (typeof(visibleCotes[cn]) === 'undefined')
            continue;
        
        var cote = visibleCotes[cn];
        var measuredLevelsCoteBar = graph.append('line')
                .attr('class', 'levels line ' + cn)
                .attr('x1', 0)
                .attr('x2', graphWidth)
                .attr('y1', y(cote.level))
                .attr('y2', y(cote.level));
    }
    
    if (hasData) {
        graph.append("g")
                .attr("class", "grid")
                .call(yAxisTicks.tickSize(-graphWidth, 0, 0).tickFormat(""));
    }

    graph.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + graphHeight + ')')
            .call(xAxis)
            .selectAll("text")  
            .style('text-anchor', 'end')
            .attr('transform', 'rotate(-45)');

    graph.append('g')
            .attr('class', 'y axis')
            .call(yAxis);

    graph.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", -(margin.left - 20))
            .attr("x", -(graphHeight / 2))
            .attr("text-anchor", "middle")
            .text(L('waterLevelUnit.' + options.waterLevelUnit));

    // Mouseover content

    if (options.interactive && hasData) {
        var focus = graph.append("g")
                .attr("class", "focus")
                .style("display", "none");

        focus.append("circle")
                .attr("r", 4.5);

        focus.append("text")
                .attr("x", 9)
                .attr("dy", ".35em");

        graph.append("rect")
                .attr("class", "overlay")
                .attr("width", graphWidth)
                .attr("height", graphHeight)
                .on("mouseover", function () {
                    focus.style("display", null);
                })
                .on("mouseout", function () {
                    focus.style("display", "none");
                })
                .on("mousemove", mousemove);

        var bisectDate = d3.bisector(function(d) { return d.timeParsed; }).left;

        var mouseoverFormat = d3.timeFormat("%d.%m.%y %H:%M");

        function mousemove(event) {
            var x0 = x.invert(d3.pointer(event)[0]);
            var i = bisectDate(measuredLevels, x0, 1);
            var d0 = measuredLevels[i - 1];
            var d1 = measuredLevels[i];
            if (typeof(d0) !== 'undefined' && typeof(d1) !== 'undefined') {
                var d = x0 - d0.timeParsed > d1.timeParsed - x0 ? d1 : d0;
                var level = d.level;
                if (options.waterLevelUnit === 'WaterLevelInCm') {
                    level = Math.floor(level);
                }
                focus.attr("transform", "translate(" + x(d.timeParsed) + "," + y(d.level) + ")");
                // Generate 2 tooltippy lines positioned at the top right of the focus point
                // and, if necessary, adjust their horizontal alignment so they don't get clipped at the right side
                var text = focus.select("text").text(null);
                var textIndent = 12;
                var line1 = text.append('tspan').attr('x', textIndent).attr('y', '-2.2em').text(level + ' ' 
                         + L('waterLevelUnit.' + options.waterLevelUnit + '.unit'));
                var line2 = text.append('tspan').attr('x', textIndent).attr('dy', '1.2em')
                        .text(mouseoverFormat(d.timeParsed));
                var textWidth = Math.max(line1.node().getComputedTextLength(), line2.node().getComputedTextLength());
                var container = svg.node().parentNode;
                var adjustment = (margin.left + x(d.timeParsed) + textIndent + textWidth) - getElementClientAreaWidth(container);
                if (adjustment > 0)
                    text.attr('transform', 'translate(-' + adjustment + ',0)');
                else
                    text.attr('transform', null);
            }
        }
    }
    
    // Remove d3's default font styles which preveant styling by CSS
    svg.selectAll('[font-family="sans-serif"]').attr('font-family', null);
    svg.selectAll('[font-size="10"]').attr('font-size', null);
    
    return svg;
}

// Gets the number of characters required to display a water level
function getLevelDisplayLength(level) {
    var str = level.toString();
    return str.length - (str.indexOf('.') !== -1 ? 1 : 0); // do not count dots towards length
}

function polyline() {
    var x;
    var y;
    
    function generatePolyline(data) {
        var segments = [];
        for (var i = 0; i < data.length; i++)
            segments.push(x(data[i]) + ',' + y(data[i]));
        return segments.join(' ');
    }
    
    generatePolyline.x = function(callback) {
        x = callback;
        return generatePolyline;
    };
    
    generatePolyline.y = function(callback) {
        y = callback;
        return generatePolyline;
    };
    
    return generatePolyline;
}

function GraphPartition(name, yMin, cote) {
    this.name = name;
    this.cote = cote;
    this.yMin = yMin;
    this.yMax = null;
    this.clipPath = null;
    this.clipPathId = 'clip-' + name;
}

GraphPartition.prototype.createClipPath = function(svg, xMin, xMax) {
    if (this.clipPath !== null)
        svg.select('#' + this.clipPathId).remove();

    this.clipPath = svg.append('clipPath')
            .attr('id', this.clipPathId)
            .append('rect')
                .attr('x', xMin)
                .attr('y', this.yMax)
                .attr('width', Math.abs(xMax - xMin))
                .attr('height', Math.abs(this.yMax - this.yMin));
};

function getSurpassedCoteName(level, coteNames, visibleCoteData) {
    var lastCoteName = null;
    for (var i = 0; i < coteNames.length; i++) {
        var cn = coteNames[i];
        if (!visibleCoteData[cn] || level < visibleCoteData[cn].level) {
            break;
        }
        lastCoteName = cn;
    }
    return lastCoteName;
}

function GLEntry(type, clazz, text) {
    this.type = type;
    this.clazz = clazz;
    this.text = text;
}

function GraphLegend(fontFamily, fontSize) {
    this.entries = [];
    this.entrySpacing = 30;
    this.entryIndicatorWidth = 20;
    this.entryIndicatorHeight = 20;
    this.entryIndicatorSpacing = 8;
    this.textHeight = 14; // hardcoded due to cross-browser irregularities in determining text height
    this.lineSpacing = 8;
    this.width = 0;
    this.height = 0;
    this.fontFamily = fontFamily;
    this.fontSize = fontSize;
}

GraphLegend.prototype = {
    create: function(svg, totalWidth) {
        var g = svg.append('g')
                .attr('class', 'legend');
        
        if (this.entries.length === 0)
            return g;
        
        this.width = 0;
        
        var last = null;
        var verticalOffset = 0;
        var lineHeight = 0;
        var lineWidth = 0;
        for (var i = 0; i < this.entries.length; i++) {
            var e = this.createEntry(g, this.entries[i]);
            var horizontalOffset = lineWidth;
            if (lineWidth !== 0)
                horizontalOffset += this.entrySpacing;
            var lastLineWidth = lineWidth;
            lineWidth = horizontalOffset + e.width;
            if (last !== null && lineWidth > totalWidth) {
                if (lastLineWidth > this.width)
                    this.width = lastLineWidth;
                verticalOffset += lineHeight + this.lineSpacing;
                horizontalOffset = 0;
                lineWidth = e.width;
            }
            if (e.height > lineHeight)
                lineHeight = e.height;
            if (last !== null)
                e.g.attr('transform', 'translate(' + horizontalOffset + ',' + verticalOffset + ')');
            last = e;
        }
        if (lineWidth > this.width)
            this.width = lineWidth;
        this.height = verticalOffset + lineHeight;
        
        return g;
    },
    
    createEntry: function(parentGroup, entry) {
        var g = parentGroup.append('g');
        if (entry.type === 'area') {
            g.append('rect')
                    .attr('class', entry.clazz)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', this.entryIndicatorWidth)
                    .attr('height', this.entryIndicatorHeight);
        }
        else if (entry.type === 'line') {
            g.append('line')
                    .attr('class', entry.clazz)
                    .attr('x1', 0)
                    .attr('y1', this.entryIndicatorHeight / 2)
                    .attr('x2', this.entryIndicatorWidth)
                    .attr('y2', this.entryIndicatorHeight / 2);
        }
        var text = g.append('text').text(entry.text);
        var preTextWidth = this.entryIndicatorWidth + this.entryIndicatorSpacing;
        var textWidth = getTextWidth(text.node().textContent, this.fontFamily, this.fontSize);
        text.attr('x', preTextWidth)
                .attr('y', this.entryIndicatorHeight - this.textHeight / 2 + 2); // approx. vertically aligned
        return {
            g: g,
            width: preTextWidth + textWidth,
            height: this.entryIndicatorHeight
        };
    },
    
    getWidth: function() {
        return this.width;
    },
    
    getHeight: function() {
        return this.height;
    }
};

function parseCssNumber(val) {
    if (typeof(val) === 'string') {
        return parseInt(val, 10);
    } else if (typeof(val) === 'number') {
        return val;
    } else {
        return 0;
    }
}

function getElementClientAreaWidth(el) {
    var cs = window.getComputedStyle(el);
    var width = parseCssNumber(cs.getPropertyValue('width'));
    var paddingLeft = parseCssNumber(cs.getPropertyValue('padding-left'));
    var paddingRight = parseCssNumber(cs.getPropertyValue('padding-right'));
    var borderLeft = parseCssNumber(cs.getPropertyValue('border-left'));
    var borderRight = parseCssNumber(cs.getPropertyValue('border-right'));
    return width - paddingLeft - paddingRight - borderLeft - borderRight;
}

function getTextWidth(text, fontFamily, fontSize) {
    var context = textWidthCanvas.getContext('2d');

    if (typeof(fontSize) === 'number' || (typeof(fontSize) === 'string' && !fontSize.endsWith('px'))) {
        fontSize += 'px';
    }
    context.font = fontSize + ' ' + fontFamily;

    return context.measureText(text).width;
}

function removeClass(selector, clazz) {
    document.querySelectorAll(selector).forEach((el) => {
        const clazzAttr = el.getAttribute('class');
        if (!clazzAttr) {
            return;
        }
        const classes = clazzAttr.trim().split(/\s+/);
        const idx = classes.indexOf(clazz);
        if (idx === -1) {
            return;
        }
        classes.splice(idx, 1);
        el.setAttribute('class', classes);
    });
}


module.exports = {
    drawGraph: drawGraph
}
